import React from "react";
import CategoryTable from "./categorytable";
import AddCategory from "./categoryadd";

const Index = () => {
  return (
    <div className="w-full h-full rounded-xl bg-transparent  mx-4 flex flex-col gap-8 ">
      <div className="p-4 bg-blue-50/50 shadow-xl rounded-xl md:px-8 bg-transparent flex flex-col gap-4">
        <span className="text-lg font-thin text-gray-800/70">
          {"Kategori Ekle"}
        </span>
        <div className="flex flex-col h-full gap-4">
          <AddCategory />
        </div>
      </div>
      <div className="p-4 bg-blue-50/50 shadow-xl rounded-xl md:px-8 bg-transparent flex flex-col gap-4">
        <span className="text-lg font-thin text-gray-800/70">
          {"Kategoriler"}
        </span>
        <div className="flex flex-col h-full gap-4">
          <CategoryTable />
        </div>
      </div>
    </div>
  );
};

export default Index;
