import { Radio, Spinner } from "@material-tailwind/react";
import EditGold from "./editProducts/editGoldProduct";
import EditDiamond from "./editProducts/edtDiamondProduct";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "../../constants/appConfig";

const Index = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const getProduct = async () => {
    try {
      const API = API_URL + "/product/findById?productId=" + productId;

      const response = await fetch(API);
      const data = await response.json();
      const product = data;
      setProduct(product);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      getProduct();
    }
  }, []);

  return (
    <>
      <div className="w-full h-full shadow-2xl p-4 ">
        Ürün Düzenleme
        {loading ? (
          <div className="py-4">
            <Spinner color="blue" className="w-48 h-48" />
          </div>
        ) : (
          <div className="py-4 flex flex-col items-center justify-center">
            {product.productGold === true ? (
              <EditGold fetchedProduct={product} />
            ) : product.productGold === false ? (
              <EditDiamond fetchedProduct={product} />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default Index;
