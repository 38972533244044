import React from "react";
import {
  Drawer,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import logo from "../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import {
  ArchiveBoxIcon,
  BanknotesIcon,
  PhotoIcon,
  Square3Stack3DIcon,
  HomeIcon,
  CircleStackIcon,
} from "@heroicons/react/24/outline";

const Leftmenu = () => {
  const [open, setOpen] = React.useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div>
        <Button
          onClick={openDrawer}
          className="bg-blue-500 font-[Inter] text-white rounded-xl items-center shadow-xl "
        >
          Menü
        </Button>
      </div>
      <Drawer open={open} onClose={closeDrawer}>
        <div className="mb-2 flex items-center justify-between ">
          <img
            onClick={() => navigate("/")}
            src={logo}
            alt="logo"
            className="w-36 h-36 cursor-pointer rounded-full"
          />
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
          <hr />
        </div>
        <List>
          <ListItem onClick={() => navigate("/")} className="font-[Inter]">
            <ListItemPrefix>
              <HomeIcon className="w-6 h-6" />
            </ListItemPrefix>
            Ana Sayfa
          </ListItem>
          <ListItem
            onClick={() => navigate("/products")}
            className="font-[Inter]"
          >
            <ListItemPrefix>
              <ArchiveBoxIcon className="w-6 h-6" />
            </ListItemPrefix>
            Ürünler
          </ListItem>
          <ListItem
            onClick={() => navigate("/category")}
            className="font-[Inter]"
          >
            <ListItemPrefix>
              <Square3Stack3DIcon className="w-6 h-6" />
            </ListItemPrefix>
            Kategoriler
          </ListItem>
          <ListItem
            onClick={() => navigate("/mainpageupdates")}
            className="font-[Inter]"
          >
            <ListItemPrefix>
              <PhotoIcon className="w-6 h-6" />
            </ListItemPrefix>
            Resimler
          </ListItem>
          <ListItem onClick={() => navigate("/price")} className="font-[Inter]">
            <ListItemPrefix>
              <BanknotesIcon className="w-6 h-6" />
            </ListItemPrefix>
            Fiyatlar
          </ListItem>
          <ListItem onClick={() => navigate("/backup")} className="font-[Inter]">
            <ListItemPrefix>
              <CircleStackIcon className="w-6 h-6" />
            </ListItemPrefix>
            Yedekleme
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
};
export default Leftmenu;
