import { useState } from "react";
import { API_URL } from "../constants/appConfig";
import Logo from "../assets/images/transparentLogo.svg";
import myToast from "../components/toast";
const Loggin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    // Örneğin, giriş işlemini bir API'ye istek göndererek gerçekleştirebilirsiniz
    try {
      fetch(API_URL + "/user/userLogin", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,

          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: username,
          userPassword: password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.userId) {
            myToast({ status: "success", message: "Giriş Başarılı" });
            sessionStorage.setItem("userLoggedIn", true);
            window.location.reload();
          } else {
            myToast({
              status: "error",
              message: "Kullanıcı adı veya şifre hatalı",
            });
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full h-screen bg-[#FBF8F4] flex flex-row justify-center items-center">
      <div className="bg-white font-[Inter] p-8 rounded-lg shadow-xl flex flex-col items-center">
        <img src={Logo} alt="Logo" className="w-20 h-20 mb-4" />
        <h2 className="text-2xl font-semibold mb-4">Giriş Yap</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-700 font-semibold"
            >
              Kullanıcı Adı
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-3 py-2 border rounded-md mt-1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 font-semibold"
            >
              Şifre
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-2 border rounded-md mt-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 w-full  text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Giriş Yap
          </button>
        </form>
      </div>
    </div>
  );
};

export default Loggin;
