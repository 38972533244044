import { CircleStackIcon } from "@heroicons/react/24/outline";
import React from "react";
import { API_URL } from "../../constants/appConfig";
import { Spinner } from "@material-tailwind/react";
import { toast } from "react-toastify";

const Index = () => {
  const [loading, setLoading] = React.useState(false);
  const getBackup = async () => {
    setLoading(true);
    console.log("Başlangıç");

    try {
      const API = API_URL + "/backup/SynchBackup";
      const response = await fetch(API);

      // Response'u kontrol edin
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.text(); // Yanıtı metin olarak alın
      toast.success("Yedekleme işlemi başarılı.");
    } catch (error) {
      console.log("Hata:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full rounded-xl bg-transparent  mx-4  pt-16 ">
      <div className="px-2 md:px-8 bg-transparent flex flex-col gap-4">
        <div className="flex flex-col h-full gap-4">
          <div className="flex flex-row flex-wrap gap-9 justify-start items-center relative z-10">
            <a onClick={getBackup}>
              {loading ? (
                <div className="absolute top-0 left-0 w-full h-full bg-[#000000]/70 z-20 flex justify-center items-center">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#D18974]">
                    <Spinner color="deep-orange" className="w-32 h-32" />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col w-[22rem]  h-56 gap-5 bg-[#D18974]/70 p-2 cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-xl rounded-[29px]">
                  <div className="flex justify-end items-center">
                    <div className="w-[80px] h-[80px] m-5 flex justify-end items-start">
                      <CircleStackIcon />
                    </div>
                  </div>
                  <div className="flex justify-start font-semibold items-end text-[24px] m-3">
                    Yedekle
                  </div>
                </div>
              )}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
