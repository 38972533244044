import {
  ArchiveBoxIcon,
  BanknotesIcon,
  PhotoIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import DasboardItem from "../../components/DashboardItem";

const Index = () => {
  const dashbordItems = [
    {
      title: "Ürünler",
      link: "/products",
      icon: <ArchiveBoxIcon className="w-12 h-12" />,
    },
    {
      title: "Kategoriler",
      link: "/category",
      icon: <Square3Stack3DIcon className="w-12 h-12" />,
    },
    {
      title: "Resimler",
      link: "/mainpageupdates",
      icon: <PhotoIcon className="w-12 h-12" />,
    },
    {
      title: "Fiyatlar",
      link: "/price",
      icon: <BanknotesIcon con className="w-12 h-12" />,
    },
  ];

  return (
    <div className="w-full h-full rounded-xl bg-transparent  mx-4  pt-16 ">
      <div className="px-2 md:px-8 bg-transparent flex flex-col gap-4">
        <div className="flex flex-col h-full gap-4">
          <div className="flex flex-row flex-wrap gap-9 justify-start items-center relative z-10">
            {dashbordItems.map((item, index) => (
              <DasboardItem
                key={index}
                title={item.title}
                link={item.link}
                icon={item.icon}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
