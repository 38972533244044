import {
  Card,
  Checkbox,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Input,
  Select,
  Option,
  Spinner,
} from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../constants/appConfig";
import myToast from "../../../components/toast";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const [savingProduct, setSavingProduct] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [image, setImage] = useState();
  const [imageBase64Data, setImageBase64Data] = useState();
  const [visible, setVisible] = useState(false);
  const [carat, setCarat] = useState(null);
  const [carats, setCarats] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [productGram, setProductGram] = useState(null);
  const [gainMilyem, setGainMilyem] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fileInputRef = useRef(null); // useRef ile bir referans oluşturuluyor

  const triggerFileInput = () => {
    fileInputRef.current.click(); // Butona tıklandığında dosya seçme inputunu tetikletiyoruz
  };

  const getAllCategory = async () => {
    try {
      const API =
        API_URL + "/category/getAllCategoryByCurrency" + "?currency=true";
      const response = await fetch(API);
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getAllCarat = async () => {
    try {
      const API = API_URL + "/carat/getAllCarat";
      const response = await fetch(API);
      const data = await response.json();
      setCarats(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSelectCategory = (selectedCategory) => {
    const categoryId = selectedCategory.categoryId;
    const API =
      API_URL +
      "/subCategory/getSubCategoryByCategoryId" +
      "?categoryId=" +
      categoryId;
    fetch(API)
      .then((response) => response.json())
      .then((data) => {
        setSubCategories(data);
      });
    setCategory(selectedCategory);
  };
  const handleSelectSubCategory = (selectedSubCategory) => {
    setSelectedSubCategory(selectedSubCategory);
  };

  useEffect(() => {
    setLoading(true);
    getAllCategory();
    getAllCarat();
    setLoading(false);
  }, []);

  const saveProduct = async (data) => {
    const API = API_URL + "/product/saveProduct";
    const response = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
  };

  const handleSaveProduct = async () => {
    if (!productName) {
      myToast({ status: "warn", message: "Ürün adı boş olamaz" });
      return;
    } else if (!carat) {
      myToast({ status: "warn", message: "Ayar seçimi yapınız" });
      return;
    } else if (!gainMilyem) {
      myToast({ status: "warn", message: "Kazanç milyemi boş olamaz" });
      return;
    } else if (!productGram) {
      myToast({ status: "warn", message: "Gram boş olamaz" });
      return;
    } else if (!category.categoryId) {
      myToast({ status: "warn", message: "Kategori seçimi yapınız" });
      return;
    } else if (!image) {
      myToast({ status: "warn", message: "Resim seçimi yapınız" });
      return;
    } else {
      try {
        setSavingProduct(true);
        const productData = {
          productName: productName,
          productDescription: productDescription,
          productCaratFk: {
            caratId: carat,
          },
          productGainMilyem: gainMilyem,
          productGram: productGram,
          productVisible: visible ? true : false,
          productCategoryFk: {
            categoryId: category.categoryId,
            currency: true,
          },
          productSubCategoryFk: selectedSubCategory
            ? { subcategoryId: selectedSubCategory.subcategoryId }
            : null,
          imageBase64Data: imageBase64Data,
          productGold: true,
        };

        const response = await saveProduct(productData);
        if (response?.productId) {
          myToast({ status: "success", message: "Ürün başarıyla eklendi" });
          navigate("/products");
        } else {
          myToast({
            status: "error",
            message: "Ürün eklenirken hata oluştu",
          });
        }
      } catch (error) {
        myToast({ status: "error", message: "Ürün eklenirken hata oluştu" });
      }
      setSavingProduct(false);
    }
  };

  const handleFileUpload = (event) => {
    try {
      const file = event.target.files[0]; // Yüklenen dosyayı alın
      const aspectRatio = 3 / 4; // Aspect ratio (3:4)

      // Resmi base64'e çevirme işlemi
      setImage(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          const calculatedAspectRatio = width / height;

          // Aspect ratio kontrolü
          if (calculatedAspectRatio !== aspectRatio) {
            // Aspect ratio uyumsuzsa, kullanıcıya bir uyarı verilebilir
            myToast({
              status: "warn",
              message: "Resmin boyutları 3/4 boyutunda olmalıdır örn: 750*1000",
            });
          } else {
            // Aspect ratio uyumlu ise, resmi state içerisine atama
            setImageBase64Data(reader.result);
          }
        };
      };
    } catch (error) {
      myToast({ status: "error", message: "Resim yüklenirken hata oluştu" });
    }
  };

  return (
    <>
      <Card className="w-full h-full shadow-none p-4 ">
        Altın ekleme
        <div className="relative h-72 w-full flex justify-center">
          <img
            className="h-[300px] w-[225px] rounded-xl object-cover object-center shadow-xl"
            src={imageBase64Data || "https://placehold.co/750x1000"}
            alt="nature_image"
          />
          <input
            type="file"
            id="file-upload"
            accept="image/jpeg, image/png, image/jpg, image/heic" // Sadece resim dosyalarını seçebilmesi için
            ref={fileInputRef} // useRef ile oluşturulan referans burada atanıyor
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <label
            htmlFor="file-upload"
            className="absolute  flex items-center justify-center cursor-pointer"
            onClick={triggerFileInput} // Butona tıklandığında triggerFileInput fonksiyonu çağrılıyor
          >
            <Button
              className="w-[225px] rounded-none rounded-t-xl opacity-75 font-[Inter]"
              variant="gradient"
              color="pink"
              size="sm"
            >
              Resim ekle
            </Button>
          </label>
        </div>
        {loading ? (
          <CardBody className="px-0 flex flex-col gap-2">
            <Spinner color="blue" size="sm" />
          </CardBody>
        ) : (
          <CardBody className="px-0 flex flex-col gap-2">
            <Typography
              color="gray"
              className="mt-2 pl-2 italic text-sm font-normal"
            >
              *Boyutunun 750x1000 px (3/4 oran) olmasına dikkat edin
            </Typography>
            <Typography
              color="gray"
              className="mt-2 pl-2 italic text-sm font-normal"
            >
              *Ürün başlığının 20 karakterden fazla olmamasına dikkat edin
            </Typography>

            <Input
              label="Ürün Adı"
              value={productName}
              onChange={(e) => {
                if (e.target.value.length <= 20) {
                  setProductName(e.target.value);
                }
              }}
            />

            <Input
              label="Ürün Açıklaması"
              onChange={(e) => setProductDescription(e.target.value)}
            />
            <Input
              type="number"
              label="Kazanç Milyemi"
              onChange={(e) => setGainMilyem(e.target.value)}
            />
            {/*    <Input
                  label="Tuning Milyemi"
                  onChange={(e) => setProductTuningMilyem(e.target.value)}
            /> */}
            <Input
              type="number"
              label="Gram"
              onChange={(e) => setProductGram(e.target.value)}
            />
            {loading ? <Spinner color="blue" size="sm" /> : null}
            <Select label="Ana Kategori" onChange={handleSelectCategory}>
              {categories.map((item) => (
                <Option key={item.categoryId} value={item}>
                  {item.categoryName}
                </Option>
              ))}
            </Select>
            {subCategories.length > 0 && (
              <Select label="Alt Kategori" onChange={handleSelectSubCategory}>
                {subCategories.map((item) => (
                  <Option key={item.subcategoryId} value={item}>
                    {item.subcategoryName}
                  </Option>
                ))}
              </Select>
            )}
            <Select label="Ayar" onChange={(val) => setCarat(val.caratId)}>
              {carats.map((item) => (
                <Option key={item.caratId} value={item}>
                  {item.caratName}
                </Option>
              ))}
            </Select>

            <label
              htmlFor="horizontal-list-vue"
              className="flex w-40 cursor-pointer hover:bg-black/5 items-center gap-4 rounded-lg px-3 "
            >
              <Checkbox
                id="horizontal-list-vue"
                ripple={false}
                color="blue"
                onChange={() => setVisible(!visible)}
                className="hover:before:opacity-0 "
                containerProps={{
                  className: "p-0",
                }}
              />
              <Typography
                color="blue-gray"
                className="font-medium font-[Inter] "
              >
                Görünürlük
              </Typography>
            </label>
          </CardBody>
        )}
        <CardFooter className="flex justify-end">
          <Button variant="text" color="red" className="font-[Inter] mr-1">
            <span>İPTAL ET</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            className="font-[Inter] "
            loading={savingProduct}
            onClick={() => handleSaveProduct()}
          >
            <span>KAYDET</span>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Index;
