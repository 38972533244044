import HeaderItems from "./headerSliderUpdate/index";
import CarouselItems from "./carouselItemsUpdate/index";

const Index = ({ lang }) => {
  return (
    <div className="w-full h-full px-4 flex flex-col pt-16 z-0">
      <div className="w-full flex flex-col justify-center items-start gap-4">
        <span className="text-md font-light font-[Inter]">
          Ana Sayfa Resim Güncellemeleri
        </span>
      </div>
      <div className="w-full  flex flex-col justify-center items-start gap-4 flex-grow">
        <div className="w-full">
          <HeaderItems />
        </div>
        <div className="w-full">
          <CarouselItems />
        </div>
      </div>
    </div>
  );
};

export default Index;
