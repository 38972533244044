import { Card, Typography, Button, CardBody } from "@material-tailwind/react";
import { useState, useRef, useEffect } from "react";
import { API_URL } from "../../../constants/appConfig";
import myToast from "../../../components/toast";

const Index = ({ lang }) => {
  useEffect(() => {
    getAllImage();
  }, []);

  const [images, setImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [updatingImages, setUpdatingImages] = useState(false);

  const handleFileUpload = (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const imageData = reader.result;
        const image = new Image();
        image.src = imageData;

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          const aspectRatio = width / height;
          const wantedAspectRatio = 1920 / 400;

          if (aspectRatio.toFixed(2) !== wantedAspectRatio.toFixed(2)) {
            myToast({
              status: "error",
              message: "Resim boyutları uygun değil",
            });
            return;
          } else {
            const imageItem = {
              imageBase64Data: imageData,
              imageSection: "slider",
              imageActive: true,
              imageId: images.length + 1,
            };
            setNewImages((prevImages) => [...prevImages, imageItem]);
            const imageSetData = {
              imageBase64Data: imageData,
              imageSection: "slider",
              imageActive: true,
              imageId: images.length + 1,
            };
            setImages((prevImages) => [...prevImages, imageSetData]);
          }
        };
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error(error);
      myToast({
        status: "error",
        message: "Resim yüklenirken bir hata oluştu",
      });
    }
  };

  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleImageRemove = (index) => {
    try {
      const updatedImages = [...images];
      const removedImage = updatedImages.splice(index, 1)[0];
      setImages(updatedImages);
      setDeletedImages((prevDeletedImages) => [
        ...prevDeletedImages,
        removedImage,
      ]);
      setNewImages((prevNewImages) =>
        prevNewImages.filter((_, idx) => idx !== index)
      );
    } catch (error) {
      console.error(error);
      myToast({
        status: "error",
        message: "Resim kaldırılırken bir hata oluştu",
      });
    }
  };

  const sendDeletedImages = async () => {
    try {
      for (const image of deletedImages) {
        const response = await fetch(
          `${API_URL}/image/delete?imageId=${image.imageId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to delete image");
        }
      }
      myToast({
        status: "success",
        message: "Resim/Resimler başarıyla silindi",
      });
    } catch (error) {
      console.error(error);
      myToast({
        status: "error",
        message: "Resim/Resimler silinirken bir hata oluştu",
      });
    }
  };

  const sendNewImages = async () => {
    try {
      for (const image of newImages) {
        if (
          !deletedImages.find(
            (deletedImage) => deletedImage.imageId === image.imageId
          )
        ) {
          const response = await fetch(API_URL + "/image/saveImage", {
            method: "POST",
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              imageBase64Data: image.imageBase64Data,
              imageSection: "slider",
              imageActive: true,
            }),
          });
          if (!response.ok) {
            myToast({
              status: "error",
              message: "Resim kaydedilirken bir hata oluştu",
            });
          } else {
            myToast({
              status: "success",
              message: "Resim başarıyla kaydedildi",
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderImageList = () => {
    return images.map((image, index) => (
      <div
        key={image.imageId}
        className="flex justify-around flex-col md:flex-row  items-center gap-1"
      >
        {image?.imageBase64Data && (
          <img
            className="rounded-xl w-full md:w-4/5 max-w-[90vw] h-auto"
            src={image.imageBase64Data}
            alt={`image_${index}`}
          />
        )}
        <Button
          size="sm"
          className="w-full md:1/5  font-[Inter] md:text-lg sm:text-sm "
          color="red"
          onClick={() => handleImageRemove(index)}
        >
          GÖRSELİ Ana Ekrandan Kaldır
        </Button>
      </div>
    ));
  };

  const getAllImage = async () => {
    try {
      const response = await fetch(
        `${API_URL}/image/getImagesBySection?section=slider&active=true&size=50000`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch images");
      }
      const data = await response.json();
      setImages(data.content);
    } catch (error) {
      console.error(error);
      myToast({
        status: "error",
        message: "Resimler alınırken bir hata oluştu",
      });
    }
  };

  const handleUpdateSliderImages = async () => {
    try {
      setUpdatingImages(true);
      await sendDeletedImages();
      await sendNewImages();
      setUpdatingImages(false);
    } catch (error) {
      console.error(error);
      myToast({
        status: "error",
        message: "Görseller güncellenirken bir hata oluştu",
      });
    }
  };

  return (
    <div className="w-full h-full px-4 gap-4 flex flex-col z-0">
      <Card className="w-full bg-white shadow-2xl rounded-2xl flex flex-col justify-center items-start gap-4 flex-grow">
        <CardBody className="w-full">
          <div className="mb-4">
            <span className="text-xs font-[Inter] font-light">
              Slider görselini güncelleyebilirsiniz. Resimler 1920*400 px
              boyutlarında olmalıdır.
            </span>
          </div>
          <div className="mb-4">
            <input
              accept="image/jpeg, image/png, image/jpg, image/heic"
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              hidden
            />
            <Button
              className="font-[Inter]"
              color="blue"
              onClick={triggerFileInput}
            >
              GÖRSEL Ekle
            </Button>
          </div>
          <div className="mb-4">
            {images.length > 0 && (
              <div className="flex flex-col gap-2 font-[Inter]">
                <Typography color="gray">Önceki Resimler</Typography>
                {renderImageList()}
              </div>
            )}
          </div>
          <div className="mb-4 w-full ">
            <Button
              loading={updatingImages}
              color="blue"
              className="w-full font-[Inter]"
              onClick={handleUpdateSliderImages}
            >
              GÖRSELLERİ Güncelle
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Index;
