import React from "react";
import Products from "../pages/products/index";
import MainPageUpdates from "../pages/mainpageupdates/index";
import Home from "../pages/home/index";
import AddProduct from "../pages/products/addProduct";
import EditProduct from "../pages/products/editProduct";
import Category from "../pages/categoryedit/index";
import Price from "../pages/price/index";
import Backup from "../pages/backup/index";
const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/price",
    element: <Price />,
  },
  {
    path: "/mainpageupdates",
    element: <MainPageUpdates />,
  },
  {
    path: "/category",
    element: <Category />,
  },
  {
    path: "/addproduct",
    element: <AddProduct />,
  },
  {
    path: "/editproduct",
    element: <EditProduct />,
  },
  {
    path: "/backup",
    element: <Backup />,
  },
  {
    path: "*",
    element: <Home />,
  },
];
export default routes;
