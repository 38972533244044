import myToast from "../../components/toast";
import React from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/16/solid";
import { useEffect, useState } from "react";
import { API_URL } from "../../constants/appConfig";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
  Input,
  DialogFooter,
} from "@material-tailwind/react";

export default function CategoryTable() {
  const [categories, setCategories] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState({});
  const [selectedDeleteMainCategory, setSelectedDeleteMainCategory] = useState(
    {}
  );
  const [selectedDeleteSubCategory, setSelectedDeleteSubCategory] = useState(
    {}
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState({});
  const getAllCategory = async () => {
    try {
      const API = API_URL + "/fullCategory/getAllFullCategory";
      const response = await fetch(API);
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const [openMainUpdate, setOpenMainUpdate] = React.useState(false);
  const handleOpenMainModal = () => setOpenMainUpdate(!openMainUpdate);
  const [openSubUpdate, setOpenSubUpdate] = React.useState(false);
  const handleOpenSubModal = () => setOpenSubUpdate(!openSubUpdate);
  const [openMainDelete, setOpenMainDelete] = React.useState(false);
  const handleOpenMainDelete = () => setOpenMainDelete(!openMainDelete);
  const [openSubDelete, setOpenSubDelete] = React.useState(false);
  const handleOpenSubDelete = () => setOpenSubDelete(!openSubDelete);

  useEffect(() => {
    getAllCategory();
  }, []);
  const handleDeleteMainCategory = async (category) => {
    setOpenMainDelete(true);
    setSelectedDeleteMainCategory(category);
  };
  const handleDeleteSubCategory = async (subcategory) => {
    setOpenSubDelete(true);
    setSelectedDeleteSubCategory(subcategory);
  };
  const handleDeleteSubCategoryData = async () => {
    try {
      const API = `${API_URL}/subCategory/deleteSubCategoryById?subCategoryId=${selectedDeleteSubCategory.subcategoryId}`;
      const response = await fetch(API, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        myToast({ status: "success", message: "Alt Kategori Silindi" });
        getAllCategory();
        handleOpenSubDelete();
      } else {
        myToast({ status: "error", message: "Alt Kategori Silinemedi" });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeleteMainCategoryData = async () => {
    try {
      const API = `${API_URL}/category/deleteCategoryById?categoryId=${selectedDeleteMainCategory.categoryId}`;
      const response = await fetch(API, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        myToast({ status: "success", message: "Ana Kategori Silindi" });
        getAllCategory();
        handleOpenMainDelete();
      } else {
        myToast({ status: "error", message: "Ana Kategori Silinemedi" });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUpdateMainCategory = async (category) => {
    setSelectedMainCategory(category);
    handleOpenMainModal();
  };
  const handleUpdateMainCategoryData = async () => {
    try {
      const API = API_URL + "/category/saveCategory";
      const response = await fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedMainCategory),
      });
      const data = await response.json();
      if (data) {
        myToast({ status: "success", message: "Kategori Güncellendi" });
        getAllCategory();
        handleOpenMainModal();
      } else {
        myToast({ status: "error", message: "Kategori Güncellemedi" });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleUpdateSubCategory = async (subcategory, categoryId) => {
    setSelectedSubCategory({
      ...subcategory,
      subcategoryCategoryFk: categoryId,
    });
    handleOpenSubModal();
  };

  const handleUpdateSubCategoryData = async () => {
    try {
      const API = API_URL + "/subCategory/saveSubCategory";
      const response = await fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedSubCategory),
      });
      const data = await response.json();
      if (data) {
        myToast({ status: "success", message: "Alt Kategori Güncellendi" });
        getAllCategory();
        handleOpenSubModal();
      } else {
        myToast({ status: "error", message: "Alt Kategori Güncellemedi" });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <div className="overflow-x-auto w-full mt-4">
        <table className="table-auto w-full md:text-md text-xs lg:text-lg">
          <thead>
            <tr>
              <th className="px-4 py-2">Kategori Adı</th>
              <th className="px-4 py-2">Kategori Açıklama</th>
              <th className="px-4 py-2">Kategori Güncelle</th>
              <th className="px-4 py-2">Alt Kategori Adı</th>
              <th className="px-4 py-2">Alt Kategori Güncelle</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((item) => (
              <React.Fragment key={item.categoryId}>
                <tr className="border border-blue-300 border-2 ">
                  <td
                    rowSpan={item.subcategories.length + 1}
                    className=" border-l-2 border-blue-300 px-4 py-2"
                  >
                    {item.categoryName}
                  </td>
                  <td
                    rowSpan={item.subcategories.length + 1}
                    className="border px-4 py-2"
                  >
                    {item.categoryDescription}
                  </td>
                  <td
                    rowSpan={item.subcategories.length + 1}
                    className="border px-4 py-2 items-center justify-center text-center"
                  >
                    <button className="rounded-full cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-lg mr-1 bg-blue-500 text-white p-3 ">
                      <PencilIcon
                        onClick={() => handleUpdateMainCategory(item)}
                        className="h-5 w-5"
                      />
                    </button>
                    <button className="rounded-full cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-lg mr-1 bg-red-500 text-white p-3 ">
                      <TrashIcon
                        onClick={() => handleDeleteMainCategory(item)}
                        className="h-5 w-5"
                      />
                    </button>
                  </td>
                </tr>
                {item.subcategories.map((sub, index) => (
                  <tr key={sub.subcategoryId}>
                    <td className="border px-4 py-2">{sub.subcategoryName}</td>
                    <td className=" border-r-2 border-blue-300 px-4 py-2 flex flex-row items-center justify-center">
                      <button className="rounded-full cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-lg mr-1 bg-blue-500 text-white p-3 ">
                        <PencilIcon
                          onClick={() =>
                            handleUpdateSubCategory(sub, item.categoryId)
                          }
                          className="h-5 w-5"
                        />
                      </button>
                      <button className="rounded-full cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-lg mr-1 bg-red-500 text-white p-3 ">
                        <TrashIcon
                          onClick={() => handleDeleteSubCategory(sub)}
                          className="h-5 w-5"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
          <Dialog open={openMainUpdate} handler={handleOpenMainModal}>
            <DialogHeader>Ana Kategori Güncelle</DialogHeader>
            <DialogBody className="flex flex-col gap-4">
              <div>
                <Input
                  value={selectedMainCategory?.categoryName}
                  onChange={(e) =>
                    setSelectedMainCategory({
                      ...selectedMainCategory,
                      categoryName: e.target.value,
                    })
                  }
                  label="Ana Kategori Adı"
                  className="font-[Inter]"
                />
              </div>
              <div>
                <Input
                  value={selectedMainCategory?.categoryDescription}
                  className="font-[Inter]"
                  onChange={(e) => {
                    setSelectedMainCategory({
                      ...selectedMainCategory,
                      categoryDescription: e.target.value,
                    });
                  }}
                  label="Ana Kategori Açıklama"
                />
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={handleOpenMainModal}
                className="mr-1 font-[Inter]"
              >
                <span>İPTAL</span>
              </Button>
              <Button
                variant="gradient"
                color="green"
                className="font-[Inter]"
                onClick={handleUpdateMainCategoryData}
              >
                <span>KAYDET</span>
              </Button>
            </DialogFooter>
          </Dialog>
          <Dialog open={openSubDelete} handler={handleOpenSubDelete}>
            <DialogHeader>Alt Kategori Sil</DialogHeader>
            <DialogBody className="flex flex-col gap-4">
              <span>
                {selectedDeleteSubCategory?.subcategoryName} isimli alt
                kategoriyi silmek istediğinize emin misiniz?
              </span>
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={handleOpenSubDelete}
                className="mr-1 font-[Inter]"
              >
                <span>İPTAL</span>
              </Button>
              <Button
                variant="gradient"
                color="green"
                className="font-[Inter]"
                onClick={handleDeleteSubCategoryData}
              >
                <span>KAYDET</span>
              </Button>
            </DialogFooter>
          </Dialog>
          <Dialog open={openMainDelete} handler={handleOpenMainDelete}>
            <DialogHeader>Alt Kategori Sil</DialogHeader>
            <DialogBody className="flex flex-col gap-4">
              <span>
                {selectedDeleteMainCategory?.categoryName} isimli ana kategoriyi
                silmek istediğinize emin misiniz?
              </span>
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={handleOpenMainDelete}
                className="mr-1 font-[Inter]"
              >
                <span>İPTAL</span>
              </Button>
              <Button
                variant="gradient"
                color="green"
                className="font-[Inter]"
                onClick={handleDeleteMainCategoryData}
              >
                <span>KAYDET</span>
              </Button>
            </DialogFooter>
          </Dialog>
          <Dialog open={openSubUpdate} handler={handleOpenSubModal}>
            <DialogHeader>Alt Kategori Güncelle</DialogHeader>
            <DialogBody className="flex flex-col gap-4">
              <div>
                <Input
                  value={selectedSubCategory?.subcategoryName}
                  onChange={(e) =>
                    setSelectedSubCategory({
                      ...selectedSubCategory,
                      subcategoryName: e.target.value,
                    })
                  }
                  label="Alt Kategori Adı"
                  className="font-[Inter]"
                />
              </div>
              <div>
                <Input
                  value={selectedSubCategory?.subcategoryDescription}
                  className="font-[Inter]"
                  onChange={(e) => {
                    setOpenSubUpdate({
                      ...selectedSubCategory,
                      subcategoryDescription: e.target.value,
                    });
                  }}
                  label="Alt Kategori Açıklama"
                />
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                variant="text"
                color="red"
                onClick={handleOpenSubModal}
                className="mr-1 font-[Inter]"
              >
                <span>İPTAL</span>
              </Button>
              <Button
                variant="gradient"
                color="green"
                className="font-[Inter]"
                onClick={handleUpdateSubCategoryData}
              >
                <span>KAYDET</span>
              </Button>
            </DialogFooter>
          </Dialog>
        </table>
      </div>
    </>
  );
}
