import React, { useEffect, useState } from "react";
import { API_URL } from "../../../constants/appConfig";
import { useNavigate } from "react-router-dom";
import myToast from "../../../components/toast";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  CardFooter,
  Spinner,
  Dialog,
} from "@material-tailwind/react";
import { PencilIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import statusOnlineIcon from "../../../assets/images/statusOnline.svg";
import statusOfflineIcon from "../../../assets/images/statusOffline.svg";

const DiamondProducts = () => {
  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openHideModal, setOpenHideModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [searchText, setSearchText] = useState("");

  const handleOpenDeleteModal = (product) => {
    setOpenDeleteModal(!openDeleteModal);
    setSelectedProduct(product);
  };
  const handleOpenHideModal = (product) => {
    setSelectedProduct(product);
    setOpenHideModal(!openHideModal);
  };
  const handleEditProduct = (product) => {
    navigate("/editproduct?productId=" + product.productId);
  };

  const handleYesDeleteProduct = () => {
    const API =
      API_URL +
      "/product/deleteProductById?productId=" +
      selectedProduct.productId;
    fetch(API, {
      method: "DELETE",
    }).then((response) => {
      if (response.ok) {
        myToast({
          status: "success",
          message: "Ürün başarıyla silindi",
        });
        getAllProducts();
        handleOpenDeleteModal();
      } else {
        myToast({
          status: "error",
          message: "Ürün silinirken bir hata oluştu",
        });
      }
    });
  };
  const handleYesHideProduct = () => {
    const API =
      API_URL +
      "/product/hideProductById?productId=" +
      selectedProduct.productId;
    fetch(API, {
      method: "DELETE",
    }).then((response) => {
      if (response.ok) {
        myToast({
          status: "success",
          message: "Ürün başarıyla gizlendi",
        });
        getAllProducts();
        handleOpenHideModal();
      } else {
        myToast({
          status: "error",
          message: "Ürün gizlenirken bir hata oluştu",
        });
      }
    });
  };

  const navigate = useNavigate();
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const changePage = (page) => {
    if (page >= 0) {
      onPageChange(page);
    }
  };

  const getAllProducts = async () => {
    setLoading(true);
    try {
      let API = "";
      if (searchText) {
        const encodedSearchText = encodeURIComponent(searchText);
        API = `${API_URL}/product/getAllProductIgnore?productGold=false&page=${currentPage}&size=10&param=${encodedSearchText}`;
      } else {
        API = `${API_URL}/product/findByGoldAndUsd?productGold=false&page=${currentPage}&size=10&sort=createdAt,desc`;
      }

      const response = await fetch(API);
      const data = await response.json();
      setProducts(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const handleChangeSearchText = (text) => {
    setSearchText(text);
  };

  useEffect(() => {
    getAllProducts();
  }, [currentPage, searchText]);

  const TABLE_HEAD = [
    "RESİM",
    "İSİM",
    "GRAM",
    "AYAR",
    "DURUM",
    "HAM FİYAT",
    "FİYAT",
    "",
  ];

  return (
    <Card className="w-full h-full shadow-2xl mx-4 pt-10">
      <CardHeader floated={false} shadow={false} className="rounded-none ">
        <div className="flex flex-col justify-between md:flex-row md:items-center gap-2 p-2">
          <Input
            label={"Ara"}
            value={searchText}
            color="pink"
            onChange={(e) => handleChangeSearchText(e.target.value)}
            icon={<MagnifyingGlassIcon className="h-5 w-5" />}
          />
          <Button
            onClick={() => navigate("/addproduct")}
            className="w-44"
            size="md"
            color="pink"
          >
            {"Ürün ekle"}
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="flex justify-center items-center h-52">
            <Spinner color="blue" size="xl" />
          </div>
        ) : (
          <div className="overflow-x-auto w-full mt-4">
            <table className="min-w-max w-full table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b-2 border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="pl-2 font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {products?.map((item, index) => {
                  const isLast = index === products.length - 1;
                  const classes = isLast
                    ? "px-4 py-2"
                    : "px-4 py-2 border-b border-blue-gray-50";

                  return (
                    <tr key={item.productId}>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Avatar
                            src={API_URL + "/" + item.productImage}
                            alt={item.productName}
                            size="lg"
                            variant="rounded"
                            className="border w-[75px] h-[100px] object-contain border-blue-gray-50 bg-blue-gray-50/50"
                          />
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-[Inter] text-sm font-bold"
                        >
                          {item.productName.length > 25
                            ? item.productName.substring(0, 25) + "..."
                            : item.productName}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          color="blue-gray"
                          className="font-[Inter] text-sm font-bold"
                        >
                          {item.productGram}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          color="blue-gray"
                          className="font-[Inter] text-sm font-bold"
                        >
                          {item.productTuningMilyem}
                        </Typography>
                      </td>
                      <td className={classes}>
                        {item.productVisible !== undefined && (
                          <img
                            src={
                              item.productVisible
                                ? statusOnlineIcon
                                : statusOfflineIcon
                            }
                            className="ml-6 w-4"
                            alt={
                              item.productVisible ? "statusTrue" : "statusFalse"
                            }
                          />
                        )}
                      </td>
                      <td className={classes}>
                        <Typography
                          color="blue-gray"
                          className="font-[Inter] text-sm font-bold"
                        >
                          {item.productRawPrice}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          color="blue-gray"
                          className="font-[Inter] text-sm font-bold"
                        >
                          {Math.round(item.productPrice * 100) / 100}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Tooltip content={"Ürünü Düzenle"}>
                          <IconButton className="text-gray-600" variant="text">
                            <PencilIcon
                              className="h-5 w-5"
                              onClick={() => handleEditProduct(item)}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip content={"Ürünü Sil"}>
                          <IconButton className="text-gray-600" variant="text">
                            <TrashIcon
                              className="h-5 w-5"
                              onClick={() => handleOpenDeleteModal(item)}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip content={"Ürünü Gizle"}>
                          <IconButton className="text-gray-600" variant="text">
                            <EyeIcon
                              className="h-5 w-5"
                              onClick={() => handleOpenHideModal(item)}
                            />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </CardBody>
      <CardFooter className="w-full flex flex-row justify-between">
        <Button
          variant="outlined"
          size="sm"
          disabled={currentPage <= 0}
          onClick={() => changePage(currentPage - 1)}
        >
          Önceki
        </Button>

        <Button
          variant="outlined"
          size="sm"
          disabled={currentPage >= totalPages - 1}
          onClick={() => changePage(currentPage + 1)}
        >
          Sonraki
        </Button>
      </CardFooter>
      <Dialog size="sm" open={openDeleteModal} handler={handleOpenDeleteModal}>
        <div className="p-8">
          <Typography
            color="blue-gray"
            className="font-bold text-center text-lg"
          >
            Ürünü silmek istediğinize emin misiniz?
          </Typography>
          <div className="flex justify-center gap-4 mt-6">
            <Button
              onClick={handleOpenDeleteModal}
              color="red"
              className="w-24"
            >
              Hayır
            </Button>
            <Button
              onClick={handleYesDeleteProduct}
              color="green"
              className="w-24"
            >
              Evet
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog size="sm" open={openHideModal} handler={handleOpenHideModal}>
        <div className="p-8">
          <Typography
            color="blue-gray"
            className="font-bold text-center text-lg"
          >
            Ürünü gizlemek istediğinize emin misiniz?
          </Typography>
          <div className="flex justify-center gap-4 mt-6">
            <Button onClick={handleOpenHideModal} color="red" className="w-24">
              Hayır
            </Button>
            <Button
              onClick={handleYesHideProduct}
              color="green"
              className="w-24"
            >
              Evet
            </Button>
          </div>
        </div>
      </Dialog>
    </Card>
  );
};

export default DiamondProducts;
