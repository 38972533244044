import React from "react";
import { Button, Input } from "@material-tailwind/react";
import { useState } from "react";
import { API_URL } from "../../../constants/appConfig";
import myToast from "../../../components/toast";
import { useRef } from "react";

const CarouselUpdate = ({ category, key, que, categories }) => {
  const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const [selectedCategory, setSelectedCategory] = useState(category);
  const handleFileUpload = (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        const image = new Image();
        image.src = imageData;

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          const aspectRatio = width / height;
          const wantedAspectRatio = 3 / 4;

          if (aspectRatio.toFixed(2) !== wantedAspectRatio.toFixed(2)) {
            myToast({
              status: "error",
              message: "Resim boyutları uygun değil",
            });
            return;
          } else {
            setSelectedCategory({
              ...selectedCategory,
              imageBase64Data: imageData,
            });
          }
        };
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error(error);
      myToast({
        status: "error",
        message: "Resim yüklenirken bir hata oluştu",
      });
    }
  };
  const handleSelectCategory = (e) => {
    try {
      const selectedCategory = categories.find(
        (item) => item.categoryId === e.target.value
      );
      setSelectedCategory({
        ...selectedCategory,
        category: {
          categoryId: selectedCategory?.categoryId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateCarousel = async () => {
    try {
      const response = await fetch(
        `${API_URL}/homePageCategory/saveHomePageCategory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedCategory),
        }
      );

      if (response.ok) {
        myToast({
          status: "success",
          message: "İlk kategori başarıyla güncellendi",
        });
      } else {
        myToast({
          status: "error",
          message: "İlk kategori güncellenirken bir hata oluştu",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="mb-4 flex flex-col gap-2 shadow-2xl rounded-xl p-8 ">
      {que + 1}. Kategori
      <div className="mb-4">
        <input
          accept="image/jpeg, image/png, image/jpg, image/heic"
          type="file"
          ref={fileInputRef}
          onChange={(e) => handleFileUpload(e, 1)}
          hidden
        />
        <Button
          className="font-[Inter]"
          color="blue"
          onClick={triggerFileInput}
        >
          GÖRSELİ EKLE
        </Button>
      </div>
      <div className="w-full flex items-center justify-center">
        <img
          src={selectedCategory?.imageBase64Data}
          alt="categoryOne"
          className="w-auto max-h-60 rounded-md shadow-md"
          style={{ aspectRatio: "2 / 3" }}
        />
      </div>
      <Input
        label="İlk Kategori Başlığı"
        value={selectedCategory?.homePageCategoryName}
        onChange={(e) =>
          setSelectedCategory({
            ...selectedCategory,
            homePageCategoryName: e.target.value,
          })
        }
      />
      <select
        size="sm"
        label="Kategori"
        onChange={handleSelectCategory}
        value={selectedCategory?.category?.categoryId}
        style={{
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "8px",
        }}
      >
        {categories.map((item) => (
          <option
            key={item.categoryId}
            value={item.categoryId}
            style={{ color: "gray", fontWeight: "normal" }}
          >
            {item.categoryName}
          </option>
        ))}
      </select>
      <div className="w-full flex justify-center items-center">
        <Button
          color="blue"
          className="w-full font-[Inter]"
          onClick={handleUpdateCarousel}
        >
          Kategoriyi güncelle
        </Button>
      </div>
    </div>
  );
};

export default CarouselUpdate;
