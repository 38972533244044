import React, { useEffect } from "react";
import { useState } from "react";
import { API_URL } from "../../../constants/appConfig";
import myToast from "../../../components/toast";
import CarouselUpdate from "./carouselUpdateCard";

const Index = () => {
  const [categories, setCategories] = useState([]);
  const [allCarouselItems, setAllCarouselItems] = useState([]);

  const getAllCategories = async () => {
    try {
      const response = await fetch(
        `${API_URL}/fullCategory/getAllFullCategory`
      );
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getHomePageCategories = async () => {
    try {
      const response = await fetch(
        `${API_URL}/homePageCategory/getAllHomePageCategory`
      );
      const data = await response.json();
      if (data.length === 0) {
        myToast({
          status: "error",
          message: "Ana sayfa kategorileri bulunamadı",
        });
        return;
      } else {
        setAllCarouselItems(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategories();
    getHomePageCategories();
  }, []);
  return (
    <div className="w-full h-full px-4 gap-4 flex flex-col z-0">
      <div className="w-full p-4 flex flex-col justify-center items-start gap-4 flex-grow">
        <div className="mb-4">
          <span className="text-lg font-[Inter] font-light">
            Ana ekranda gözükecek olan rastgele kategorileri seçiniz.
          </span>
        </div>
        <div className="w-full flex flex-wrap justify-around gap-4">
          {allCarouselItems.map((item, index) => (
            <CarouselUpdate
              key={index}
              que={index}
              category={item}
              categories={categories}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
