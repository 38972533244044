import React, { useState } from "react";
import { Radio } from "@material-tailwind/react";
import GoldProduct from "./getProducts/goldProducts";
import DiamondProduct from "./getProducts/diamondProducts";

const Index = () => {
  const [productType, setProductType] = useState("Gold");

  return (
    <div className="w-full h-full mx-4 pt-10">
      <div className="flex flex-col items-center justify-center w-full ">
        <h1 className="text-2xl font-bold">Ürünler</h1>
        <div className="flex flex-row items-center justify-start mt-4 w-full">
          <Radio
            name="type"
            value={productType === "Gold" ? true : false}
            label="Altın Ürünleri"
            onClick={() => setProductType("Gold")}
            ripple={true}
          />
          <Radio
            name="type"
            label="Döviz Ürünleri"
            value={productType === "Diamond" ? true : false}
            onClick={() => setProductType("Diamond")}
            ripple={true}
          />
        </div>
        <div className="py-4 w-full h-full">
          {productType === "Gold" ? (
            <GoldProduct />
          ) : productType === "Diamond" ? (
            <DiamondProduct />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Index;
