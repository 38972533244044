import React from "react";
import Leftmenu from "../components/Leftmenu";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "../routes/route";

const Main = () => {
  return (
    <Router>
      <div className="w-full h-full  px-4 py-2 flex flex-col justify-start gap-4">
        <Leftmenu />
        <div className="w-full font-[Inter] flex flex-col md:flex-row justify-center items-center">
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Main;
