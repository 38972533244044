import {
  Card,
  Checkbox,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Input,
  Spinner,
} from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../constants/appConfig";
import myToast from "../../../components/toast";
import { useNavigate } from "react-router-dom";
const Index = ({ fetchedProduct }) => {
  const [savingProduct, setSavingProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(fetchedProduct);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productCategory, setProductCategory] = useState({});
  const [productSubcategory, setProductSubcategory] = useState({});
  const [productCarat, setProductCarat] = useState({});
  const [carats, setCarats] = useState([]);
  const [productImage, setProductImage] = useState(null);
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click(); // Butona tıklandığında dosya seçme inputunu tetikletiyoruz
  };
  useEffect(() => {
    setLoading(true);
    getAllCategory();
    getAllCarat();
    if (fetchedProduct) {
      setProduct(fetchedProduct);
      setProductImage(fetchedProduct.imageBase64Data);
      if (fetchedProduct.productCaratFk) {
        setProductCarat(fetchedProduct.productCaratFk.caratId);
      }
      if (fetchedProduct.productCategoryFk) {
        handleSelectCategory(fetchedProduct.productCategoryFk.categoryId);
        if (fetchedProduct.productSubCategoryFk) {
          handleSelectSubCategory(
            fetchedProduct.productSubCategoryFk.subcategoryId
          );
        }
      }
    }
    setLoading(false);
  }, []);
  const getAllCarat = async () => {
    try {
      const API = API_URL + "/carat/getAllCarat";
      const response = await fetch(API);
      const data = await response.json();
      setCarats(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllCategory = async () => {
    try {
      const API =
        API_URL + "/category/getAllCategoryByCurrency" + "?currency=true";
      const response = await fetch(API);
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSelectCategory = (selectedCategory) => {
    const API =
      API_URL +
      "/subCategory/getSubCategoryByCategoryId" +
      "?categoryId=" +
      selectedCategory;
    fetch(API)
      .then((response) => response.json())
      .then((data) => {
        setSubCategories(data);
      });
    setProductCategory(selectedCategory);
    setProductSubcategory(null);
  };
  const handleSelectSubCategory = (selectedSubCategory) => {
    setProductSubcategory(selectedSubCategory);
  };

  const saveProduct = async (data) => {
    try {
      const API = API_URL + "/product/saveProduct";
      var productData = {};
      if (productImage === null && data.productImage) {
        productData = {
          ...data,
          productCategoryFk: { categoryId: productCategory },
          productSubCategoryFk: productSubcategory
            ? { subcategoryId: productSubcategory }
            : null,
          productCaratFk: { caratId: productCarat },
        };
      } else if (productImage !== null && data.productImage) {
        productData = {
          ...data,
          imageBase64Data: productImage,
          productCategoryFk: { categoryId: productCategory },
          productSubCategoryFk: productSubcategory
            ? { subcategoryId: productSubcategory }
            : null,
          productCaratFk: { caratId: productCarat },
        };
      } else {
        productData = {
          ...data,
          imageBase64Data: productImage,
          productCategoryFk: { categoryId: productCategory },
          productSubCategoryFk: productSubcategory
            ? { subcategoryId: productSubcategory }
            : null,
          productCaratFk: { caratId: productCarat },
        };
      }

      const response = await fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productData),
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSaveProduct = async () => {
    if (!product.productName) {
      myToast({ status: "warn", message: "Ürün adı boş olamaz" });
      return;
    } else if (!product.productGainMilyem) {
      myToast({ status: "warn", message: "Kazanç milyemi boş olamaz" });
      return;
    } else if (!product.productGram) {
      myToast({ status: "warn", message: "Gram boş olamaz" });
      return;
    } else {
      setSavingProduct(true);

      try {
        const response = await saveProduct(product);
        if (response?.productId) {
          myToast({ status: "success", message: "Ürün başarıyla güncellendi" });
          navigate("/products");
          // window.location.href = "/products";
        } else {
          myToast({
            status: "error",
            message: "Ürün güncellenirken hata oluştu",
          });
        }
      } catch (error) {
        myToast({ status: "error", message: "Ürün eklenirken hata oluştu" });
      }
      setSavingProduct(false);
    }
  };

  const handleFileUpload = (event) => {
    try {
      const file = event.target.files[0]; // Yüklenen dosyayı alın
      const aspectRatio = 3 / 4; // Aspect ratio (2:3)

      // Resmi base64'e çevirme işlemi
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          const calculatedAspectRatio = width / height;

          // Aspect ratio kontrolü
          if (calculatedAspectRatio !== aspectRatio) {
            // Aspect ratio uyumsuzsa, kullanıcıya bir uyarı verilebilir
            myToast({
              status: "warn",
              message: "Resmin boyutları 3/4 boyutunda olmalıdır örn: 750*1000",
            });
          } else {
            // Aspect ratio uyumlu ise, resmi state içerisine atama
            setProductImage(reader.result);
          }
        };
      };
    } catch (error) {
      myToast({ status: "error", message: "Resim yüklenirken hata oluştu" });
    }
  };

  return (
    <>
      <Card className="w-full h-full shadow-none p-4 ">
        Altın ekleme
        <div className="relative h-72 w-full flex justify-center">
          <img
            className="h-[300px] w-[225px] rounded-xl object-cover object-center shadow-xl"
            src={
              productImage
                ? productImage
                : API_URL + "/" + fetchedProduct?.productImage ||
                  "https://placehold.co/750x1000"
            }
            alt="nature_image"
          />
          <input
            type="file"
            id="file-upload"
            accept="image/jpeg, image/png, image/jpg, image/heic" // Sadece resim dosyalarını seçebilmesi için
            ref={fileInputRef} // useRef ile oluşturulan referans burada atanıyor
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <label
            htmlFor="file-upload"
            className="absolute  flex items-center justify-center cursor-pointer"
            onClick={triggerFileInput} // Butona tıklandığında triggerFileInput fonksiyonu çağrılıyor
          >
            <Button
              className="w-[225px] rounded-none rounded-t-xl opacity-75 font-[Inter]"
              variant="gradient"
              color="pink"
              size="sm"
            >
              Resim ekle
            </Button>
          </label>
        </div>
        {loading ? (
          <CardBody className="px-0 flex flex-col gap-2">
            <Spinner color="blue" size="sm" />
          </CardBody>
        ) : (
          <CardBody className="px-0 flex flex-col gap-2">
            <Typography
              color="gray"
              className="mt-2 pl-2 italic text-sm font-normal"
            >
              *Boyutunun 750x1000 px (3/4 oran) olmasına dikkat edin
            </Typography>
            <Typography
              color="gray"
              className="mt-2 pl-2 italic text-sm font-normal"
            >
              *Ürün başlığının 20 karakterden fazla olmamasına dikkat edin
            </Typography>

            <Input
              label="Ürün Adı"
              value={product.productName}
              onChange={(e) => {
                if (e.target.value.length <= 20) {
                  setProduct({
                    ...product,
                    productName: e.target.value,
                  });
                }
              }}
            />
            <Input
              label="Ürün Açıklaması"
              value={product.productDescription}
              onChange={(e) =>
                setProduct({
                  ...product,
                  productDescription: e.target.value,
                })
              }
            />
            <Input
              type="number"
              value={product.productGainMilyem}
              label="Kazanç Milyemi"
              onChange={(e) =>
                setProduct({
                  ...product,
                  productGainMilyem: e.target.value,
                })
              }
            />
            <Input
              type="number"
              value={product.productGram}
              label="Gram"
              onChange={(e) =>
                setProduct({
                  ...product,
                  productGram: e.target.value,
                })
              }
            />
            <select
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              value={productCarat}
              onChange={(e) => setProductCarat(e.target.value)}
            >
              {carats.map((carat) => (
                <option key={carat.caratId} value={carat.caratId}>
                  {carat.caratName}
                </option>
              ))}
            </select>
            <select
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              value={productCategory}
              onChange={(e) => handleSelectCategory(e.target.value)}
            >
              {categories.map((category) => (
                <option key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </option>
              ))}
            </select>
            {subCategories.length > 0 ? (
              <select
                className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                value={productSubcategory}
                onChange={(e) => handleSelectSubCategory(e.target.value)}
              >
                {subCategories.map((subCategory) => (
                  <option
                    key={subCategory.subcategoryId}
                    value={subCategory.subcategoryId}
                  >
                    {subCategory.subcategoryName}
                  </option>
                ))}
              </select>
            ) : null}
            <label
              htmlFor="horizontal-list-vue"
              className="flex w-40 cursor-pointer hover:bg-black/5 items-center gap-4 rounded-lg px-3 "
            >
              <Checkbox
                id="horizontal-list-vue"
                ripple={false}
                checked={product.productVisible}
                color="blue"
                onChange={() =>
                  setProduct({
                    ...product,
                    productVisible: !product.productVisible,
                  })
                }
                className="hover:before:opacity-0 "
                containerProps={{
                  className: "p-0",
                }}
              />
              <Typography
                color="blue-gray"
                className="font-medium font-[Inter] "
              >
                Görünürlük
              </Typography>
            </label>
          </CardBody>
        )}
        <CardFooter className="flex justify-end">
          <Button variant="text" color="red" className="font-[Inter] mr-1">
            <span>İPTAL ET</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            className="font-[Inter] "
            loading={savingProduct}
            onClick={() => handleSaveProduct()}
          >
            <span>KAYDET</span>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Index;
