import { useEffect } from "react";
import "./App.css";
import Main from "./pages/Main";
import { API_URL } from "./constants/appConfig";
import Loggin from "./pages/Loggin";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  const setTokenAuth = async () => {
    try {
      const response = await fetch(API_URL + "/authentication", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "tunex",
          password: "159357",
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to authenticate");
      }
      const data = await response.json();
      sessionStorage.setItem("token", data.jwt);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Fonksiyonu çağır
  setTokenAuth();

  useEffect(() => {
    setTokenAuth();
  }, []);

  const userLoggedIn = sessionStorage.getItem("userLoggedIn");

  return (
    <div className="w-full h-full flex justify-center items-center ">
      <ToastContainer />
      {userLoggedIn ? <Main /> : <Loggin />}
    </div>
  );
}

export default App;
