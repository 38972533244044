import {
  Button,
  Input,
  Select,
  Option,
  Radio,
  Checkbox,
} from "@material-tailwind/react";
import { API_URL } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import myToast from "../../components/toast";
import React from "react";

const CategoryUpdate = () => {
  const [addType, setAddType] = useState("");
  const [mainCategoryName, setMainCategoryName] = useState("");
  const [mainCategoryDescription, setMainCategoryDescription] = useState("");
  const [selectedCategoryForSub, setSelectedCategoryForSub] = useState(null);
  const [subCategoryNameForSub, setSubCategoryNameForSub] = useState(null);
  const [subCategoryDescriptionForSub, setSubCategoryDescriptionForSub] =
    useState(null);
  const [categories, setCategories] = useState([]);
  const handleSelectCategoryForSub = (e) => {
    setSelectedCategoryForSub(e);
  };
  const [categoryIsGold, setCategoryIsGold] = useState(false);

  const handleSaveMainCategory = async () => {
    try {
      if (
        mainCategoryName === "" ||
        mainCategoryName === null ||
        mainCategoryName === undefined
      ) {
        myToast({ status: "warn", message: "Kategori Adı Boş Olamaz" });
        return;
      } else if (
        mainCategoryDescription === "" ||
        mainCategoryDescription === null ||
        mainCategoryDescription === undefined
      ) {
        myToast({ status: "warn", message: "Kategori Açıklama Boş Olamaz" });
        return;
      } else {
        const API = API_URL + "/category/saveCategory";
        const response = await fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryName: mainCategoryName,
            categoryDescription: mainCategoryDescription,
            currency: categoryIsGold,
          }),
        });
        const data = await response.json();
        if (data) {
          myToast({ status: "success", message: "Kategori Eklendi" });
          window.location.reload();
        } else {
          myToast({ status: "error", message: "Kategori Eklenemedi" });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSaveSubCategory = async () => {
    try {
      if (
        selectedCategoryForSub === "" ||
        selectedCategoryForSub === null ||
        selectedCategoryForSub === undefined
      ) {
        myToast({ status: "warn", message: "Ana Kategori Seçiniz" });
        return;
      } else if (
        subCategoryNameForSub === "" ||
        subCategoryNameForSub === null ||
        subCategoryNameForSub === undefined
      ) {
        myToast({ status: "warn", message: "Alt Kategori Adı Boş Olamaz" });
        return;
      } else if (
        subCategoryDescriptionForSub === null ||
        subCategoryDescriptionForSub === "" ||
        subCategoryDescriptionForSub === undefined
      ) {
        myToast({
          status: "warn",
          message: "Alt Kategori Açıklama Boş Olamaz",
        });
        return;
      } else {
        const API = API_URL + "/subCategory/saveSubCategory";
        const response = await fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subcategoryName: subCategoryNameForSub,
            subcategoryCategoryFk: selectedCategoryForSub.categoryId,
            subcategoryDescription: subCategoryDescriptionForSub,
          }),
        });
        const data = await response.json();
        if (data) {
          myToast({ status: "success", message: "Alt Kategori Eklendi" });
          window.location.reload();
        } else {
          myToast({ status: "error", message: "Alt Kategori Eklenemedi" });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllCategory = async () => {
    try {
      const API = API_URL + "/fullCategory/getAllFullCategory";
      const response = await fetch(API);
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="w-full flex flex-wrap justify-around">
        <Radio
          name="type"
          label="Ana Kategori Ekleme"
          onClick={() => setAddType("Main")}
          ripple={true}
        />
        <Radio
          name="type"
          label="Alt Kategori Ekleme"
          onClick={() => setAddType("Sub")}
          ripple={true}
        />
        {/* MİKRO KATEGORİ EKLEME GİZLİİ
                <Radio
                  name="type"
                  label="Mikro Kategori Ekleme"
                  onClick={() => setAddType("Micro")}
                  ripple={true}
                /> */}
      </div>
      <div className="flex flex-wrap justify-between items-center  w-full">
        {addType === "Main" ? (
          <div className="flex  h-[340px] justify-around items-center  px-10 py-4 flex-col w-full ">
            <span className="text-sm font-thin text-gray-800/70">
              {"Ana Kategori Ekle"}
            </span>
            <div>
              <Input
                onChange={(e) => setMainCategoryName(e.target.value)}
                label="Ana Kategori Adı"
              />
            </div>
            <div>
              <Input
                onChange={(e) => setMainCategoryDescription(e.target.value)}
                label="Ana Kategori Açıklama"
              />
            </div>
            <div>
              <Radio
                name="categoryType"
                label="Altın Kategorisi"
                onClick={() => setCategoryIsGold(true)}
                ripple={true}
              />
              <Radio
                name="categoryType"
                label="Döviz Kategorisi"
                onClick={() => setCategoryIsGold(false)}
                ripple={true}
              />
            </div>
            <Button
              className="w-52"
              onClick={handleSaveMainCategory}
              color="blue"
              ripple="light"
            >
              {"Ekle"}
            </Button>
          </div>
        ) : addType === "Sub" ? (
          <div className="flex  h-[340px] justify-around items-center  px-10 py-4 flex-col w-full ">
            <span className="text-sm font-thin text-gray-800/70">
              {"Alt Kategori Ekle"}
            </span>
            <div>
              <Select
                size="sm"
                label="Ana Kategori"
                onChange={handleSelectCategoryForSub}
              >
                {categories.map((item) => (
                  <Option key={item.categoryId} value={item}>
                    {item.categoryName}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <Input
                label="Alt Kategori Adı"
                onChange={(e) => setSubCategoryNameForSub(e.target.value)}
              />
            </div>
            <div>
              <Input
                label="Alt Kategori Açıklama"
                onChange={(e) =>
                  setSubCategoryDescriptionForSub(e.target.value)
                }
              />
            </div>
            <Button
              className="w-52"
              onClick={handleSaveSubCategory}
              color="blue"
              ripple="light"
            >
              {"Ekle"}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CategoryUpdate;
