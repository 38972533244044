import { Radio } from "@material-tailwind/react";
import AddGold from "./addProductTypes/addGold";
import AddDiamond from "./addProductTypes/addDiamond";
import { useState } from "react";
const Index = () => {
  const [addType, setAddType] = useState("Gold");
  return (
    <>
      <div className="w-full h-full shadow-2xl p-4 ">
        Ürün ekleme
        <div>
          <Radio
            name="type"
            label="Altın Ekleme"
            onClick={() => setAddType("Gold")}
            ripple={true}
          />
          <Radio
            name="type"
            label="Pırlanta Ekleme"
            onClick={() => setAddType("Diamond")}
            ripple={true}
          />
        </div>
        <div className="py-4">
          {addType === "Gold" ? (
            <AddGold />
          ) : addType === "Diamond" ? (
            <AddDiamond />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Index;
