import { Button, Input } from "@material-tailwind/react";
import { API_URL } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import myToast from "../../components/toast";

const Index = () => {
  const [onsGoldPrice, setOnsGoldPrice] = useState({});
  const [dolarPrice, setDolarPrice] = useState({});
  const [gramGold, setGramGold] = useState({});
  const [onsGoldSetPrice, setOnsGoldSetPrice] = useState(null);
  const [dolarSetPrice, setDolarSetPrice] = useState(null);
  const [gramGoldSetPrice, setGramGoldSetPrice] = useState(null);
  const getAllPrices = async () => {
    try {
      const API = API_URL + "/price/getAllPrice";
      const response = await fetch(API);
      const data = await response.json();
      const goldPrice = data?.content.find((price) => price.priceId === "ons");
      const dolarPrice = data?.content.find(
        (price) => price.priceId === "dolar"
      );
      const gramGold = data?.content.find((price) => price.priceId === "gram");
      setGramGold(gramGold);
      setOnsGoldPrice(goldPrice);
      setDolarPrice(dolarPrice);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUpdatePrice = async (sendedData, UpdatePrice) => {
    try {
      function formatPrice(price) {
        // Fiyatın sadece sayı ve nokta/virgül içerdiğini kontrol et
        if (!/^\d+([.,]\d{0,3})?$/.test(price)) {
          return "ERROR";
        }

        // Nokta işaretini, ondalık ayırıcı olarak virgül işaretiyle değiştirme
        const normalizedPrice = price.replace(".", ",");

        // Virgül ile ayırma
        const [whole, fraction] = normalizedPrice.split(",");

        // Noktalı formatı oluştur
        const formattedWhole = whole.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

        // Kuruş kısmı varsa ekle
        const formattedPrice =
          fraction !== undefined
            ? `${formattedWhole},${fraction}`
            : formattedWhole;

        return formattedPrice;
      }

      const formattedPrice = formatPrice(UpdatePrice);

      if (
        formattedPrice === null ||
        formattedPrice === "" ||
        formattedPrice === undefined
      ) {
        myToast({ status: "error", message: "Lütfen fiyat giriniz" });
        return;
      } else if (formattedPrice === "ERROR") {
        myToast({
          status: "error",
          message:
            "Lütfen geçerli bir fiyat giriniz ÖRN: 2550,70 veya 2550 gibi",
        });
        return;
      } else {
        const formData = {
          ...sendedData,
          price: formattedPrice,
        };

        const API = API_URL + "/price/savePrice";
        const response = await fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const data = await response.text();
        if (data) {
          myToast({
            status: "success",
            message: sendedData.priceName + " Fiyatı Güncellendi",
          });

          setOnsGoldSetPrice(null);
          setDolarSetPrice(null);
          setGramGoldSetPrice(null);

          getAllPrices();
        } else {
          myToast({
            status: "error",
            message: sendedData.priceName + " Fiyatı Güncellenemedi",
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllPrices();
  }, []);

  return (
    <div className="w-full h-full rounded-xl bg-transparent mx-4 pt-16">
      <div className="px-2 md:px-8 bg-transparent flex flex-col gap-4">
        {"Döviz Kurları"}
        <div className="flex flex-col h-full gap-4">
          <span className="text-sm italic font-thin text-gray-800/70 bg-gray-300/30 p-4 rounded-lg shadow-lg m-8">
            {
              "Fiyat Güncelleme ekranında fiyatları güncelleyebilirsiniz. Güncelleme işlemi yaparken, virgül ile kuruş kısmını girebilirsiniz. Örnek: 2550,70 veya 2550 gibi."
            }
          </span>
          <div className="flex flex-wrap justify-around items-center">
            <div className="flex flex-col rounded-xl px-10 py-4 shadow-xl gap-2">
              <span className="text-lg font-thin text-gray-800/70">
                Ons Altın Fiyatı : {onsGoldPrice?.price}
              </span>
              <Input
                type="number"
                value={onsGoldSetPrice}
                onChange={(e) => setOnsGoldSetPrice(e.target.value)}
                label="Ons Altın Giriniz"
              />
              <Button
                onClick={() => handleUpdatePrice(onsGoldPrice, onsGoldSetPrice)}
                color="blue"
                ripple="light"
              >
                {"Güncelle"}
              </Button>
            </div>
            <div className="flex flex-col rounded-xl px-10 py-4 shadow-xl gap-2">
              <span className="text-lg font-thin text-gray-800/70">
                Dolar Fiyatı : {dolarPrice?.price}
              </span>
              <Input
                type="number"
                value={dolarSetPrice}
                onChange={(e) => setDolarSetPrice(e.target.value)}
                label="Dolar Fiyatı Giriniz"
              />
              <Button
                onClick={() => handleUpdatePrice(dolarPrice, dolarSetPrice)}
                color="blue"
                ripple="light"
              >
                {"Güncelle"}
              </Button>
            </div>
            <div className="flex flex-col rounded-xl px-10 py-4 shadow-xl gap-2">
              <span className="text-lg font-thin text-gray-800/70">
                Gram Altın Fiyatı : {gramGold?.price}
              </span>
              <Input
                type="number"
                value={gramGoldSetPrice}
                onChange={(e) => setGramGoldSetPrice(e.target.value)}
                label="Gram Altın Giriniz"
              />
              <Button
                onClick={() => handleUpdatePrice(gramGold, gramGoldSetPrice)}
                color="blue"
                ripple="light"
              >
                {"Güncelle"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
